import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false


// --- Slider

Vue.component('agile', () =>
    import ( /* webpackChunkName: "agile" */ 'vue-agile').then(m => m.VueAgile))


// --- Icons

import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'

import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faFax } from '@fortawesome/free-solid-svg-icons/faFax'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
// icons hier importieren

import { library } from '@fortawesome/fontawesome-svg-core'
library.add(
    faBars,
    faTimes,
    faExternalLinkAlt,
    faPhone,
    faFax,
    faEnvelope,
    faCircleNotch,
    // icons hier hinzufügen
)

Vue.component('fa', () =>
    import ( /* webpackChunkName: "fontawesome" */ '@fortawesome/vue-fontawesome').then(m => m.FontAwesomeIcon))


// --- on scroll animations

import OnScroll from 'vue-on-scroll'
Vue.use(OnScroll, { useCapture: true })

// --- analytics

// import VueAnalytics from 'vue-analytics'

// Vue.use(VueAnalytics, {
//     id: 'UA-XXX-X',
//     // #!debug
//     disabled: true
// })

// ---

Vue.component('kleine-boxen', () =>
    import ( /* webpackChunkName: "kleineBoxen" */ './components/KleineBoxen.vue'))
Vue.component('wegweiser', () =>
    import ( /* webpackChunkName: "wegweiser" */ './components/Wegweiser.vue'))
Vue.component('formular', () =>
    import ( /* webpackChunkName: "formular" */ './components/Formular.vue'))
Vue.component('pentagone', () =>
    import ( /* webpackChunkName: "pentagone" */ './components/Pentagone.vue'))


import store from './store'

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')