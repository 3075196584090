<template>
    <div id="app">
        <img class="hintergrundbild"
            src="./assets/bg/avalonorga_bg.jpg"
            srcset="./assets/bg/avalonorga_bg-400x820.jpg 400w 2x,
                    ./assets/bg/avalonorga_bg-400x820.jpg 400w,
                    ./assets/bg/avalonorga_bg-1536x1024.jpg 1536w,
                    ./assets/bg/avalonorga_bg.jpg 2508w"
            alt="hintergrund"/>
        <nav>
            <router-link to="/" id="home-button">
                <img src="./assets/logo/selectcom-logo.png" alt="selectCOM">
            </router-link>

			<button class="hamburger" @click="menuOpen = !menuOpen">
				<transition name="hamburger-toggle">
            		<fa icon="bars" key="bars" v-if="!menuOpen"/>
					<fa icon="times" key="times" v-else/>
				</transition>
			</button>

            <div class="menu" :class="{active: menuOpen}" @click="closeMenu">
                <router-link to="/" class="home">Home</router-link>
                <router-link to="/terminierung">Terminierung</router-link>
                <router-link to="/verwaltung">Verwaltung</router-link>
                <router-link to="/kommunikation">Kommunikation</router-link>
                <router-link to="/abrechnung">Abrechnung</router-link>
                <router-link to="/berichtswesen">Berichtswesen</router-link>
                <router-link to="/kontakt">Kontakt</router-link>
            </div>
        </nav>
        <main>
            <router-view/>
        </main>
        <footer>
            <div>
                <div class="adresse">
                    <p>Georg-Reismüller-Str. 49</p>
                    <p>80999 München</p>
                    <p><fa icon="phone"/> +49 89 9545713-65</p>
                    <p><fa icon="envelope"/> avalon-orga@selectcom.de</p>
                </div>
                <div>
                    <router-link to="/impressum">Impressum</router-link>
                    <router-link to="/datenschutz">Datenschutz</router-link>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

export default {
    data() {return{
        menuOpen: false
    }},
    methods: {
		closeMenu(event) {
			event.target.blur()
			this.menuOpen = false
		}
    }
}
</script>

<style lang="scss">
@use './styles/sizes';
@use './styles/colors' as *;
@use './styles/mixins' as *;

// --- Inhalt
// 1. Generell
// 2. Ueberschriften
// 3. Andere tags
// 4. Komponenten
//    i.   highlight
//    ii.  box
//    iii. columns


// --- Generell

@font-face {
	font-family: 'interstate';
	font-weight: light;
	src: url("./fonts/interstate-light-41255.ttf") format('truetype');
}

html,
body {
	margin: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
}


// --- Überschriften

h1 {
	padding-bottom: 1.56rem;
	font-size: 2rem;
	color: blau;
	line-height: 1.2em;
}

h2 {
	padding-bottom: 1.38rem;
	font-size: 1.56rem;
	line-height: initial;
}

h3 {
	padding-bottom: 1rem;
	font-size: 1.1rem;
}

h4 {
	padding-bottom: 30px;
}


// --- Andere tags

img {
	max-width: 100%;
}

ul>li {
	list-style-image: url("./assets/logo/sc_li.png");
	margin-bottom: 0.5em;

	&:last-of-type {
		margin-bottom: 0;
	}

	ol,
	ul {
		margin: {
			top: .5em;
			bottom: .5em;
		}

		li::marker {
			filter: grayscale(1);
		}
	}

	ol {
		list-style-image: initial;
	}
}

// --- Komponenten

.highlight {
	font-size: calc(1em + 1.2vw);
	color: transparent;
	margin: 1em 5%;
	text-align: center;
	line-height: initial;

    animation: blend-in .1s .3s;
    animation-fill-mode: forwards;
    @keyframes blend-in {
        from {
            color: transparent;
        }
        to {
            color: white;
        }
    }
}

.box {
	background-color: $weiss-transparent;
	padding: 3em 1em;
	box-sizing: border-box;
	backdrop-filter: blur(3px);

	> * {
		max-width: sizes.$inhalt;
		margin: auto;
	}

	h1 {
		margin-bottom: 1em;
	}

	&.transparent {
		backdrop-filter: none;
		background-color: transparent;
	}

	&.blau {
		background-color: $blau;
		color: #fff;
	}
}

.columns {
	@include spalten(2, 1.5em, 300px);

	img {
		width: 100%;
	}
}

</style>

<style lang="scss" scoped>
@use './styles/sizes';
@use './styles/colors' as *;
@use './styles/mixins' as *;

#app {
	font-family: 'interstate', Avenir, Helvetica, Arial, sans-serif;
	font-weight: light;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 150%;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: hidden;

	// background-image: url("./assets/bg/avalonorga_bg.jpg");
	// background-size: cover;
	// background-position: center;
	// background-repeat: no-repeat;
}

.hintergrundbild {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;

    z-index: -1;
}

main {
	flex: 1;
	overflow-y: scroll;
	overflow-x: hidden;

	> div {
		overflow: hidden;
	}
}

footer {
	background-color: $weiss-leicht-transparent;
	color: #000;
	font-size: 0.85rem;
	padding: 2em 0.5em;
	box-sizing: border-box;

	> div {
		width: sizes.$inhalt;
		max-width: 100%;
		margin: auto;

		> div {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

            > * + * {
                margin-left: 2em;
            }
		}
	}

	a {
		color: #444;
	}

	p {
		margin-top: 0;
	}

	svg {
		color: #333;
	}

	@media (max-width: sizes.$ipad) {
		.adresse {
			display: none;
		}
	}
}

nav {
    $img-width: .6em;
    $nav-height: 4em;

	background-color: $weiss-leicht-transparent;
	position: relative;
	height: $nav-height;

	text-align: center;
	padding: 1em;
	width: 100%;
	box-sizing: border-box;
	border-bottom: 1px solid #808080;

	.hamburger {
		display: none;

		$size: 2em;

		position: absolute;
		right: ($nav-height - $size) / 2;
		top: ($nav-height - $size) / 2;
		width: $size;
		height: $size;

		margin: 0;
		padding: 0;
		border: none;
		font-size: inherit;

		cursor: pointer;

		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: $size;
			height: $size;
		}


		.hamburger-toggle-enter-active {
			animation: blend-out .2s cubic-bezier(1, 0, .8, 1) reverse both;
		}

		.hamburger-toggle-leave-active {
			animation: blend-out .2s cubic-bezier(.5, 0, 1, 1) normal both;
		}

		@keyframes blend-out {
			0% {
				opacity: 1;
				transform: scale(1);
			}
			50% {
				opacity: 1;
				transform: scale(.5);
			}
			51% {
				opacity: 0;
			}
			100% {
				opacity: 0;
				transform: scale(.5);
			}
		}
	}

	.home {
		display: none;
	}

	.menu {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		a {
            $space-between: 1em;

			height: auto;
			color: #000;
			font-weight: bold;
			padding: 0.05em;
			margin: 0 ($space-between + $img-width / 2);
			text-decoration: none;
			border-bottom: 1px solid transparent;
			transition: border-color 0.2s;

			&.active {
				border-bottom: 1px solid #000;
			}

            &:hover,
            &:focus {
                border-bottom: 1px solid $blau;
                outline: none;
            }

			position: relative;
			&::after {
				content: '';
				background-image: url("./assets/logo/sc_logo_one.png");
				background-size: contain;
				background-position: center;
				position: absolute;
				right: -($space-between + $img-width / 2);
				top: 50%;
				transform: translate(50%, -50%);
				width: $img-width;
				height: $img-width;
			}
			&:last-of-type::after {
                display: none;
            }
		}
	}

	@media (max-width: 1100px) {
		.home {
			display: initial;
		}

		.hamburger {
			display: block;
		}

		.menu {
			width: auto;
			height: auto;

			position: absolute;
			left: 100%;
			top: calc(100% + 1px);
			transition: transform 0.2s ease-in-out;
			z-index: 10;

			display: flex;
			flex-direction: column;
			text-align: left;
			align-items: initial;

			&.active,
			&:focus-within {
				transform: translateX(-100%);
			}

			a {
				border: none;
				margin: 0;
				&::after {
					content: none;
				}
				&:hover,
				&:focus,
				&.active {
					border-bottom: none;
				}

				$img-space: .7em;

				padding: 0.5em 2em 0.5em ($img-space * 2 + $img-width);
				background-color: #fff;
				text-decoration: none;
				color: $blau;

				display: flex;
				align-items: center;

				cursor: pointer;
				
				&:hover {
					background-color: #f1f1f1;
				}

				&:hover::before,
				&.active::before,
				&:focus::before {
					filter: grayscale(0);
				}

				position: relative;
				&::before {
					content: '';
					background-image: url("./assets/logo/sc_logo_one.png");
					background-size: contain;
					background-position: center;
					position: absolute;
					left: $img-space;
					width: $img-width;
					height: $img-width;
					filter: grayscale(1);
					transition: filter 0.2s ease-in-out;
				}
			}
		}
	}


	#home-button {
		position: absolute;
		top: 0;
		left: 0;

		img {
			padding: 0.5em;
			height: $nav-height;
			box-sizing: border-box;
		}
	}
}
</style>