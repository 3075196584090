import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/terminierung',
        component: () =>
            import ( /* webpackChunkName: "terminierung" */ '../views/Terminierung.vue')
    },
    {
        path: '/abrechnung',
        component: () =>
            import ( /* webpackChunkName: "abrechnung" */ '../views/Abrechnung.vue')
    },
    {
        path: '/kommunikation',
        component: () =>
            import ( /* webpackChunkName: "kommunikation" */ '../views/Kommunikation.vue')
    },
    {
        path: '/verwaltung',
        component: () =>
            import ( /* webpackChunkName: "verwaltung" */ '../views/Verwaltung.vue')
    },
    {
        path: '/berichtswesen',
        component: () =>
            import ( /* webpackChunkName: "berichtswesen" */ '../views/Berichtswesen.vue')
    },
    {
        path: '/impressum',
        component: () =>
            import ( /* webpackChunkName: "impressum" */ '../views/Impressum.vue')
    },
    {
        path: '/datenschutz',
        component: () =>
            import ( /* webpackChunkName: "datenschutz" */ '../views/Datenschutz.vue')
    },
    {
        path: '/kontakt',
        component: () =>
            import ( /* webpackChunkName: "kontakt" */ '../views/Kontakt.vue')
    },

    {
        path: '*',
        component: () =>
            import ( /* webpackChunkName: "404" */ '../views/errors/404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    base: process.env.BASE_URL,
    routes
})

export default router