<template>
    <div class="home">
        <div class="highlight">Wir schaffen die Verbindung zwischen Termin, Patient, Therapeut und Raumbelegung bis zur Abrechnung</div>
        <div class="box">
            <h1>Perfekt koordinierte Praxis</h1>
            <h4>Sie betreiben z.B. eine Praxis ob als Arzt oder Heilpraktiker, ein Spa, Studio oder vermieten
                Räumlichkeiten und Arbeitsplätze?</h4>
            <p>                                  
                Das Praxismanagement <b>"AVALON Orga"</b> von selectCOM organisiert die digitalen Patientenakten, weist gebuchten Terminen die für das 
                Leistungsangebot erforderlichen Ressourcen wie z.B. Therapeuten bzw. Mitarbeiter sowie Praxis- und Behandlungsräume zu.
            </p>
        </div>
        <div style="height: 10em"></div>
        <div class="box">
            <p>
                <b>AVALON-Orga lässt sich individuell auf sämtliche* organisatorischen Bedürfnisse anpassen, hier als Beispiel die einer Therapie-Praxis.  
                Ihre Ressourcen perfekt abgestimmt:</b><br><br>
            </p>
            <!-- <h2 style="height: 1em; text-align: center;" v-html="pentagoneHoverText"></h2> -->
            <pentagone v-on-scroll.repeat
                @hover-start="(i, html) => pentagoneHoverText = html"
                @click="(i, html) => pentagoneHoverText = html"
                @hover-end="pentagoneHoverText = ''"
                @click-center="pentagoneHoverText = ''">
                <template #innen>
                    <span style="line-height: 120%; font-size: .85em;" v-html="pentagoneHoverText || 'AVALON<br>orga'"></span>
                </template>
                <template #aussen-1><fa icon="calendar-alt"/></template>
                <template #aussen-1-text>Termin</template>
                <template #aussen-2><fa icon="user-injured"/></template>
                <template #aussen-2-text>Patient</template>
                <template #aussen-3><fa icon="user-md"/></template>
                <template #aussen-3-text>Therapeut</template>
                <template #aussen-4><fa icon="door-open"/></template>
                <template #aussen-4-text>Raum</template>
                <template #aussen-5><fa icon="file-alt"/></template>
                <template #aussen-5-text>Abrechnung</template>
            </pentagone>
        </div>
        <div style="height: 10em"></div>
        <div class="box">
           <h2>AVALON-Orga</h2>
            <p>
                <b>ist eine moderne, lösungs- und praxisorientierte Organisations-und Verwaltungssoftware mit der einfach 
                und effizient Terminbuchungen- und planungen vorgenommen, Ressourcen zugewiesen und zugleich abgerechnet werden können.</b><br><br>
                <ul>
                    <li><b>Terminreservierungen</b> können sowohl telefonisch als auch online* entgegen genommen werden.</li>
                    <li><b>Terminüberschneidungen gibt es nicht!</b> Unsere Lösung greift, unabhängig von der Art der Buchung, auf den gleichen Kalender zu.</li>
                    <li>Zugleich ermöglichen wir über eine <b>zentrale Kunden- und Patientenverwaltung </b>sowie einen, auf ihr Leistungsangebot, abgestimmten Artikelstamm. </li>
                    <li>Zur einfachen Berechnung stellt <b>AVALON Orga</b> diverse Varianten, beginnend bei der <b>Vorauskasse</b> bis hin zur <b>Quartalsabrechnung</b>, 
                        voll- oder teilautomatisiert, zur Verfügung.</li>
                </ul>
                <br><br>
                <small>*optional</small>
            </p><br>
        </div>
        <div class="box transparent">
            <center><h2 style="color: white; font-size: 3rem; text-shadow: 0 0 .5em grey;">Unsere Lösung</h2></center>
            <div class="cards" v-on-scroll.repeat>
                <div>
                    <h4><fa icon="cloud"/></h4>
                    <p>
                        <b>Cloudbasierend</b><br> und <b>standortunabhängig</b>
                    </p>
                </div>
                <div>
                    <h4><fa icon="shield-alt"/></h4>
                    <p>
                        <b>Datensicherheit:</b><br>
                        alle Interaktionen werden verschlüsselt übertragen und bieten daher sehr hohen Schutz gegen Hacker.
                        <small>(Gleiches Prinzip wie beim Online Banking)</small>
                    </p>
                </div>
                <div>
                    <h4><fa icon="server"/></h4>
                    <p>
                        Die Lösung wird in zutrittsgesicherten <b>Hochsicherheits-Rechenzentren</b>
                        in der EU oder auf Wunsch auch ausschließlich in Deutschland gehostet.
                    </p>
                </div>
                <div>
                    <h4><fa icon="history"/></h4>
                    <p>
                        <b>Datensicherungen</b> erfolgen laufend und werden täglich an zwei weitere Standorte in Deutschland verschlüsselt übertragen.
                    </p>
                </div>
            </div>
        </div>
        <div style="height: 1em"></div>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen'
import { faUserInjured } from '@fortawesome/free-solid-svg-icons/faUserInjured'
import { faUserMd } from '@fortawesome/free-solid-svg-icons/faUserMd'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt'

import { faServer } from '@fortawesome/free-solid-svg-icons/faServer'
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory'
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud'
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons/faShieldAlt'

library.add(
    faCalendarAlt,
    faDoorOpen,
    faUserInjured,
    faUserMd,
    faFileAlt,
    faServer,
    faHistory,
    faCloud,
    faShieldAlt,
)

export default {
    data() {return{
        pentagoneHoverText: ''
    }}
}
</script>

<style lang="scss" scoped>
@use '../styles/colors' as *;
@use '../styles/mixins' as *;

.pentagone {
    font-size: 2em;
    color: white;
    text-align: center;

    svg {
        font-size: 2em;
    }

    @media (max-width: 500px) {
        font-size: 6vw;
    }
}

.cards {
    $min-width: 220px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.5em;

    @media (max-width: 4 * $min-width) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 2 * $min-width) {
        grid-template-columns: 1fr;
    }

    $icon-size: 3rem;
    $mask-position: $icon-size / 2;

    > * {
        opacity: 0;
        transform: translateY(30%);
        transition: all 0.5s ease-in-out;
        @include gestaffelte-verzoegerung(1, 4);

        padding: 1em;
        text-align: center;

        position: relative;
        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            content: '';
            background-color: $weiss-leicht-transparent;

            $mask-size: $icon-size * 5/3;
            mask: url("../assets/circle.svg")
                50% #{$mask-position}/#{$mask-size} #{$mask-size}
                no-repeat
                exclude,
                linear-gradient(#fff,#fff);
        }
    
    }

    h4 {
        font-size: $icon-size;
        text-align: center;
        margin: .5em;

        color: black;
        @supports (mask: url('')) {
            color: white;
        }
    }

    &.visible > * {
        opacity: 1;
        transform: translateY(0) !important;
    }
}
</style>